import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Spin, Input, Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import TrendingNews from "../../cards/newscard/sideBarComponents/TrendingNews";
import SmallCardContentRight from "../../cards/newscard/SmallCardContentRight";
import { Get } from "../../../../redux/features/API";
import { useTheme } from "../../../../Dashboard/ThemeContext";

export default function SearchPage() {
  const { searchValue } = useParams();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState(searchValue);
  const { themeColor } = useTheme();
  const [allBlogs, setAllBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [itemsToShow, setItemsToShow] = useState(14); // Number of items to show
  const [lge, setLge] = useState(
    window.location.pathname.includes("/en") ? "en" : "np"
  );
  const { bgColor } = useTheme();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await Get({ url: `/search/search/?q=${searchValue}` });
      if (response) {
        const filteredBlogs = response.blog.filter(
          (blog) => blog.active === true && blog.language === lge
        );
        setAllBlogs(filteredBlogs);
      }
      setLoading(false);
    };
    fetchData();
  }, [searchValue, lge]);

  const searched = () => {
    navigate(`/search/${inputValue}`);
  };

  const handleLoadMore = () => {
    setItemsToShow((prev) => prev + 10); // Load 10 more items
  };

  return (
    <div
      className="w-full flex justify-center"
      style={{ backgroundColor: bgColor }}
    >
      <div className="w-[97%] md:w-[90%]">
        {loading ? (
          <div className="flex justify-center py-10">
            <Spin size="large" />
          </div>
        ) : allBlogs.length === 0 ? (
          <div className="flex justify-center py-10">
            <h2 className="text-xl text-gray-500">No results found</h2>
          </div>
        ) : (
          <div className="w-full grid grid-cols-6">
            <div className="col-span-6 min-h-[80vh] mr-5 md:h-full overflow-scroll md:col-span-4 flex flex-wrap py-4 px-3 justify-start gap-[15px] sm:gap-[30px]">
              <div
                className="flex mt-7 w-full gap-[10px] max-h-[50px]"
                style={{ borderBottom: "2px solid #36a819" }}
              >
                <Input.Group compact style={{ display: "flex" }}>
                  <Input
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    className="text-2xl text-mukta text-[#254722] max-w-[90%] px-3 py-4 border-none active:border-none"
                    style={{ fontWeight: "550" }}
                  />
                  <Button
                    type="primary"
                    onClick={searched}
                    className="px-8 py-4"
                    style={{
                      backgroundColor: themeColor,
                      borderRadius: "100%",
                    }}
                    icon={<ArrowRightOutlined style={{ fontSize: "25px" }} />}
                  />
                </Input.Group>
              </div>

              <div className="flex flex-wrap lg:min-h-[80vh]">
                {allBlogs.slice(0, itemsToShow).map((blog) => (
                  <div
                    key={blog.id}
                    className="w-[95%] h-[150px] sm:w-[80%] lg:w-[70%] xl:w-[45%] pb-4 pt-2 px-3 rounded-md"
                    style={{ boxShadow: "0 0 16px #c6f7ba" }}
                  >
                    <SmallCardContentRight
                      title={blog.news_title}
                      sub_title={blog.news_sub_title}
                      image={`https://cms.bhandarirajan7.com.np${blog.image}`}
                      id={blog.id}
                      textBlack="true"
                    />
                  </div>
                ))}
              </div>

              {/* Load More Button */}
              {itemsToShow < allBlogs.length && (
                <Button
                  onClick={handleLoadMore}
                  type="primary"
                  className="mt-4 w-full bg-green-500 hover:bg-green-100 text-white font-bold"
                >
                  Load More
                </Button>
              )}
            </div>

            <div className="col-span-6 md:col-span-2 sm:pt-[30px]">
              <div className="sticky top-[60px]">
                <TrendingNews />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
