import { YoutubeOutlined } from "@ant-design/icons";
import React from "react";

export default function Contact({ vertical = false }) {
  return (
    <div
      className={`contact_icon ${vertical ? "flex flex-col" : "flex "} my-4 gap-[10px] `}
      style={{
        width: "100%",
      }}
    >
      <span className="contact_icon1">
        <a
          href="https://www.facebook.com/Nishanpaus?mibextid=ZbWKwL"
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ion-icon name="logo-facebook"></ion-icon>
        </a>
      </span>
      {/* <span className="contact_icon2">
        <a
          href="#"
          target="_blank"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ion-icon name="logo-linkedin"></ion-icon>
        </a>
      </span> */}
      <span className="contact_icon3">
        <a
          href="https://www.instagram.com/krishi.sanjal/ "
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ion-icon name="logo-instagram"></ion-icon>
        </a>
      </span>

      <span className="contact_icon5">
        <a
          href="https://x.com/Sanjalkrishi  "
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ion-icon name="logo-twitter"></ion-icon>
        </a>
      </span>
      <span>
        <a
          href="https://www.youtube.com/@krishisanjal"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "black" }}
        >
          <YoutubeOutlined
            style={{ fontSize: "35px" }}
            className=" hover:scale-[1.3] duration-150"
          />
        </a>
      </span>
    </div>
  );
}
// #contact_icon6 ion-icon:hover {
//   color: red;
//   background-color: whitesmoke;
//   border-radius: 100%;
//   scale: 1.5;
// }
