import React, { useEffect, useState } from "react";
import ContentLayout from "./ContentLayout";
import Ads from "../../common/cards/Ads";
import BicharBlog from "../../common/cards/newscard/sideBarComponents/BicharBlog";
import Share from "./Share";
import Contact from "../../common/cards/newscard/sideBarComponents/Contact";
import TajaSamachar from "../../common/cards/newscard/sideBarComponents/TajaSamachar";
import { useParams } from "react-router-dom";
import { useNews } from "../../../Dashboard/NewsContext";
import { Skeleton } from "antd";
import AuthorBredCrumb from "../../common/cards/AuthorBredCrumb";
import SmallAds from "../../common/cards/SmallAds";
import { useTheme } from "../../../Dashboard/ThemeContext";
import NewsArticle from "./NewsArticle";
import RoadBlocking from "../../common/cards/RoadBlocking";
import FormatNepaliDate from "../../../redux/Specials/FormatNepaliDate";
import FormatEnglishDate from "../../../redux/Specials/FormatEnglishDate";
import { useCount } from "../../../Dashboard/CountContext";
import { Get, Post } from "../../../redux/features/API";
import MyOg from "../OGtag/MyOg";

const Story = () => {
  const { newsId } = useParams();
  const { wholeNews, loading } = useNews();
  const [news, setNews] = useState(null);
  const { themeColor, bgColor } = useTheme();
  const [nepaliDate, setNepaliDate] = useState("");
  const [englishDate, setEnglishDate] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const { count } = useCount();
  const newsUrl = window.location.href; // Get current URL
  useEffect(() => {
    if (wholeNews) {
      const myNewsId = Number(newsId);
      const filteredNews = wholeNews.find((item) => item.id === myNewsId);
      // console.log(filteredNews);
      if (filteredNews) {
        setNews(filteredNews);
        setNepaliDate(FormatNepaliDate(filteredNews.self_date));
        setEnglishDate(FormatEnglishDate(filteredNews.self_date));
        // console.log(filteredNews);
      } else {
        setNews(null);
      }
    }
  }, [wholeNews, newsId]);
  useEffect(() => {
    const fetchAndPostViews = async () => {
      try {
        const response = await count;
        const filteredResponse = response.find(
          (item) => item.title === String(newsId)
        );
        const countId = filteredResponse.id;
        const response2 = await Get({ url: `/count/posts/${countId}/` });
        if (response2) {
          // console.log(response);
        }
      } catch (error) {
        console.error(error);
        // message.error("Failed to fetch share count");
      }
    };

    fetchAndPostViews();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderHtmlContent = (htmlString) => {
    if (!htmlString) return <p>No content to display.</p>;

    return (
      <div
        dangerouslySetInnerHTML={{ __html: htmlString }}
        className="content"
        style={{ lineHeight: "1.6", wordWrap: "break-word" }}
      />
    );
  };

  return (
    <div
      className="flex justify-center w-full"
      style={{ backgroundColor: bgColor }}
    >
      <div className="flex flex-col justify-center w-[97%] sm:w-[90%]">
        <RoadBlocking name="S_roadblocking_ads" />
        <Ads name="S_landscape_before_title" />
        <h2
          className={`text-${scrolled ? "2xl pl-3 md:text-3xl shadow-lg" : "3xl md:text-5xl"} 
          duration-[1s] font-bold sticky top-[59px] z-10 p-2`}
          style={{
            lineHeight: "1.5",
            transition: "font-size 0.5s ease-in-out",
            backgroundColor: bgColor,
          }}
        >
          {news ? (
            <>
              <h1>{news.news_title}</h1>
              <MyOg news={news} newsUrl={newsUrl} />
            </>
          ) : (
            <p>Loading</p>
          )}

          {/* {news ? news.news_title : "Loading..."} */}
        </h2>
        <div className="flex flex-col w-full items-center gap-12 py-4 mt-8 mb-5">
          <div className="w-full flex flex-wrap justify-between sm:px-5">
            <h1 className="flex gap-5 items-center text-center">
              {news && englishDate && nepaliDate && news.author_name && (
                <AuthorBredCrumb
                  id={`${news.author_name}`}
                  englishDate={englishDate}
                  nepaliDate={nepaliDate}
                  category={news.category_name}
                  language={news.language}
                />
              )}
            </h1>
            <span className="flex gap-[15px] justify-end w-full lg:w-auto">
              <Share
                newsTitle={
                  news ? news.news_title : "This is the heading of the news"
                }
                id={newsId}
              />
            </span>
          </div>
        </div>
        <Ads name="S_landscape_after_title" />
        <div className="w-full grid grid-cols-11 overflow-hidden">
          <div className="col-span-11 xl:col-span-7 w-full">
            {loading || !news ? (
              <Skeleton avatar paragraph={{ rows: 4 }} />
            ) : (
              <div className="flex flex-col gap-[20px] w-full">
                {news.image && (
                  <img
                    src={news.image}
                    alt="News"
                    style={{
                      border: `2px dotted ${themeColor}`,
                      borderRadius: "5px",
                    }}
                    className="w-full "
                  />
                )}
                <div style={{ backgroundColor: bgColor, width: "100%" }}>
                  {renderHtmlContent(news.news_post)}
                </div>
                {/* Render the entire HTML content, including tables and other elements */}
                {news.table_html && renderHtmlContent(news.table_html)}
              </div>
            )}
            <div className="my-5">
              <Share
                newsTitle={
                  news ? news.news_title : "This is the heading of the news"
                }
                id={newsId}
              />
            </div>
            <Ads name="S_landscape_after_content" />
          </div>
          <div className="col-span-11 xl:col-span-4 mx-5">
            <h2 className="text-2xl font-bold font-mukta">Follow Us:</h2>
            <Contact />
            <SmallAds name="S_sidebar_after_followus" />
            <div className="my-10">
              <BicharBlog />
            </div>
            <SmallAds name="S_sidebar_after_bicharblog" />
            <div className="my-10 mx-2 sticky top-[120px]">
              <TajaSamachar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
