import { Table } from "antd";
import React, { useState, useEffect } from "react";
import { Get } from "../../../redux/features/API";
import { useNews } from "../../../Dashboard/NewsContext";
import { useCount } from "../../../Dashboard/CountContext";

export default function Review() {
  const [visitorCount, setVisibleCount] = useState(0);
  const [topNews, setTopNews] = useState([]);
  const { wholeNews } = useNews();
  const { count } = useCount();

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 400,
    },
    {
      title: "Views",
      dataIndex: "views_count",
      key: "views_count",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
  ];

  // Fetch visitor count
  useEffect(() => {
    const fetchVisitorCount = async () => {
      try {
        const response = await Get({ url: "/count/total-count/" });
        setVisibleCount(response.total_visit_count);
      } catch (error) {
        console.error(error);
      }
    };

    fetchVisitorCount();
  }, []);

  // Fetch top 5 news based on views count
  useEffect(() => {
    const fetchTopNews = async () => {
      try {
        const viewsResponse = await count;

        if (!viewsResponse || !Array.isArray(viewsResponse)) {
          console.error("Invalid viewsResponse:", viewsResponse);
          return;
        }

        const sortedViews = viewsResponse
          .sort((a, b) => b.visit_count - a.visit_count)
          .slice(0, 6);

        const tableData = sortedViews
          .map((view) => {
            const matchingNews = wholeNews.find(
              (news) => String(news.id) === view.title
            );
            if (matchingNews) {
              return {
                key: matchingNews.id,
                title: matchingNews.news_title,
                views_count: view.visit_count,
                category: matchingNews.category_name,
              };
            }
            return null;
          })
          .filter((item) => item !== null);

        setTopNews(tableData);
      } catch (error) {
        console.error("Error fetching top news:", error);
      }
    };

    fetchTopNews();
  }, [count, wholeNews]);

  return (
    <div className="bg-gradient-to-r from-green-200 to-green-100 p-6 rounded-lg shadow-md">
      {/* Number of Visitors */}
      <div className="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0">
        <div className="bg-white p-6 rounded-lg shadow-lg flex-1 mx-2">
          <h2 className="text-xl font-bold text-gray-800 mb-2">
            Number of Visitors
          </h2>
          <p className="text-4xl text-green-600 font-semibold">
            {visitorCount}
          </p>
        </div>
      </div>

      {/* Top 5 Most Viewed News */}
      <div className="bg-white rounded-lg shadow-lg p-6 mt-6">
        <h2 className="text-xl font-bold text-gray-800 mb-4">
          Top 5 Most Viewed News
        </h2>
        <Table
          dataSource={topNews}
          columns={columns}
          pagination={false}
          rowClassName="hover:bg-green-50"
          scroll={{ x: "max-content" }}
        />
      </div>
    </div>
  );
}
