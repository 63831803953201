import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const BigCardContentRight = ({
  showParagraph = false,
  id,
  title,
  sub_title,
  image,
  created_date_ad,
  created_date_bs,
}) => {
  const navigate = useNavigate();
  const [lge, setLge] = useState(
    window.location.pathname.includes("/en") ? "en" : "np"
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div
      className=" h-[auto] sm:h-[450px]"
      style={{ background: " linear-gradient(to top, #006400, #ffffff)" }}
    >
      <Link
        to={
          lge === "en"
            ? `/en/story/${created_date_ad.split("T")[0].split("-").join("/")}/${id}/${title}`
            : `/story/${created_date_bs.replace(/-/g, "/")}/${id}`
        }
      >
        <div
          className="w-full group cursor-pointer h-full overflow-hidden font-mukta"
          onClick={() => {
            scrollToTop();
          }}
        >
          <div className="flex flex-col sm:flex-row h-full gap-5">
            <div className="w-full sm:w-3/5 overflow-hidden h-[300px] sm:h-full relative bg-black group">
              <img
                src={image}
                alt=""
                className="w-full h-full group-hover:opacity-80 group-hover:scale-110 duration-150 "
              />
            </div>
            <div className="w-full sm:w-2/5 flex flex-col justify-center gap-10 p-10">
              <h2
                className="text-green-900 text-3xl sm:text-3xl line-clamp-3 !font-medium leading-6"
                style={{ lineHeight: "1.5" }}
              >
                {title}
              </h2>
              {showParagraph && (
                <p
                  className="text-[#f5f5dc] text-base text-xl line-clamp-4 !font-normal "
                  style={{ lineHeight: "1.5" }}
                >
                  {sub_title}
                </p>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BigCardContentRight;
