import React from "react";
import { Helmet } from "react-helmet";

export default function MyOg({ news, newsUrl }) {
  console.log("this is from og" + news, newsUrl);
  return (
    <Helmet>
      <title>{news.news_title}</title>
      <meta property="og:title" content={news.news_title} />
      <meta property="og:description" content={news.news_sub_title} />
      {/* <meta property="og:image" content= /> */}
      <meta property="og:image" content={news?.image || `${news.image}`} />
      <meta property="og:url" content={newsUrl} />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content="Krishi Sanjal" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={news.news_title} />
      <meta name="twitter:description" content={news.news_sub_title} />
      <meta name="twitter:image" content={news.image} />
    </Helmet>
  );
}
