import React, { useState, useEffect } from "react";
import { Button, Table, Modal, message } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AdvertisementModify from "./Buttons/AdvertisementModify";
import { Get, Delete } from "../../../redux/features/API";

const AdvertisementTable = ({ reload, setReload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedAd, setSelectedAd] = useState(null);
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);
  const [adToDelete, setAdToDelete] = useState(null);
  const [largeGifUrl, setLargeGifUrl] = useState(null);
  const token = localStorage.getItem("Token");
  const headers = { Authorization: `Bearer ${token}` };

  const fetchAdvertisements = async () => {
    try {
      const response = await Get({
        url: "/advertisement/advertisement",
        headers,
      });
      // Sort response in descending order based on id
      const sortedResponse = response
        .map((ad) => ({ ...ad })) // Keep the original object structure
        .sort((a, b) => b.id - a.id); // Assuming 'id' is the property to sort by

      setDataSource(
        sortedResponse.map((ad, index) => ({ ...ad, key: index + 1 }))
      );
    } catch (error) {
      message.error("Error fetching advertisements.");
    }
  };

  useEffect(() => {
    fetchAdvertisements();
    setReload(false);
  }, [reload]);

  const showEditModal = (record) => {
    setSelectedAd(record);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setSelectedAd(null);
  };

  const handleCancelEditModal = () => {
    setIsModalOpen(false);
    setSelectedAd(null);
  };

  const handleDelete = (adId) => {
    setAdToDelete(adId);
    setIsConfirmDeleteVisible(true);
  };

  const confirmDelete = async () => {
    try {
      await Delete({
        url: `/advertisement/advertisement/${adToDelete}`,
        headers,
      });
      message.success("Advertisement deleted successfully.");
      setIsConfirmDeleteVisible(false);
      setAdToDelete(null);
      await fetchAdvertisements();
    } catch (error) {
      message.error("Error deleting advertisement.");
    }
  };

  const handleGifClick = (imageUrl, record) => {
    setLargeGifUrl(imageUrl);
    setSelectedAd(record); // Store the selected ad for modification
    setIsModalOpen(false); // Close edit modal if open
  };

  const handleGifPreviewCancel = () => {
    setLargeGifUrl(null);
    // if (selectedAd) {
    // setIsModalOpen(true); // Open modify modal if an ad is selected
    // }
  };

  const columns = [
    {
      title: "S.N",
      dataIndex: "key",
    },
    {
      title: "Title",
      dataIndex: "ads_name",
    },
    {
      title: "Image",
      dataIndex: "ads_image",
      render: (image, record) => (
        <img
          src={image}
          alt="Advertisement"
          style={{ width: 100, cursor: "pointer" }}
          onClick={() => handleGifClick(image, record)} // Pass record to handleGifClick
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Button
            type="primary"
            onClick={() => showEditModal(record)}
            className="bg-white text-black"
          >
            <EditOutlined />
          </Button>
          <Button type="danger" onClick={() => handleDelete(record.id)}>
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="Modify Advertisement"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancelEditModal}
        footer=""
        okButtonProps={{
          style: { color: "black", border: "1px solid #bdbbbb" },
        }}
      >
        <AdvertisementModify
          setReload={setReload}
          handleCancel={handleCancelEditModal}
          selectedAd={selectedAd}
        />
      </Modal>

      <Modal
        title="GIF Preview"
        visible={!!largeGifUrl}
        footer={null}
        onCancel={handleGifPreviewCancel}
        width={800}
      >
        {largeGifUrl && (
          <img src={largeGifUrl} alt="Large GIF" style={{ width: "100%" }} />
        )}
      </Modal>

      <Modal
        title="Confirm Deletion"
        visible={isConfirmDeleteVisible}
        onOk={confirmDelete}
        onCancel={() => setIsConfirmDeleteVisible(false)}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this advertisement?</p>
      </Modal>

      <Table
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

export default AdvertisementTable;
