import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faWhatsapp,
  faFacebookMessenger, // Import the Messenger icon
} from "@fortawesome/free-brands-svg-icons";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton, // Import the MessengerShareButton
} from "react-share";
import { message } from "antd/es";
import { useCount } from "../../../Dashboard/CountContext";

const Share = ({ newsTitle, id }) => {
  const newsUrl = window.location.href;
  const [shareCount, setShareCount] = useState(0);
  const { count } = useCount();

  const handleShare = () => {};

  useEffect(() => {
    const fetchShare = async () => {
      try {
        const myId = id; // Use id directly
        const response = await count;
        const filteredResponse = response.find(
          (item) => item.title === String(myId)
        );

        // console.log(filteredResponse);
        // console.log(response);

        if (filteredResponse) {
          setShareCount(filteredResponse.shares.share_count); // Set share count
        } else {
          setShareCount(0);
        }
      } catch (error) {
        console.error(error);
        // message.error("Failed to fetch share count");
      }
    };

    fetchShare();
  }, [count, id]);

  return (
    <div className="flex gap-[10px]">
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-sm font-bold text-[#8a8986]">{shareCount}</h2>
        <h2 className="text-sm font-bold text-[#8a8986]">Shares</h2>
      </div>
      <div className="flex gap-[10px]">
        <FacebookShareButton
          url={newsUrl}
          quote={newsTitle}
          onClick={handleShare}
          style={{ display: "flex", alignItems: "center" }}
        >
          <FontAwesomeIcon icon={faFacebook} className="w-5 h-5" />
        </FacebookShareButton>

        <TwitterShareButton
          url={newsUrl}
          title={newsTitle}
          onClick={handleShare}
          style={{ display: "flex", alignItems: "center" }}
        >
          {/* <FontAwesomeIcon icon={faTwitter} /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
          >
            <path
              fill="currentColor"
              d="M9.333 6.929L14.546 1H13.31L8.783 6.147L5.169 1H1l5.466 7.783L1 15h1.235l4.779-5.436L10.83 15H15zM7.641 8.852l-.554-.776L2.68 1.911h1.898l3.557 4.977l.552.776l4.623 6.47h-1.897z"
              className="w-5 h-5"
            />
          </svg>
        </TwitterShareButton>

        <WhatsappShareButton
          url={newsUrl}
          title={newsTitle}
          onClick={handleShare}
          style={{ display: "flex", alignItems: "center" }}
        >
          <FontAwesomeIcon icon={faWhatsapp} className="w-5 h-5" />
        </WhatsappShareButton>

        <FacebookMessengerShareButton
          url={newsUrl}
          title={newsTitle}
          onClick={handleShare}
          style={{ display: "flex", alignItems: "center" }}
        >
          <FontAwesomeIcon icon={faFacebookMessenger} className="w-5 h-5" />
        </FacebookMessengerShareButton>
      </div>
    </div>
  );
};

export default Share;
