import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const SmallCardContentRight = ({
  showParagraph = false,
  textBlack = false,
  id,
  title,
  sub_title,
  image,
  created_date_ad,
  created_date_bs,
}) => {
  const [lge, setLge] = useState(
    window.location.pathname.includes("/en") ? "en" : "np"
  );

  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="w-full group cursor-pointer h-full overflow-hidden font-mukta">
      <Link
        to={
          lge === "en"
            ? `/en/story/${created_date_ad.split("T")[0].split("-").join("/")}/${id}/${title}`
            : `/story/${created_date_bs.replace(/-/g, "/")}/${id}`
        }
      >
        <div
          className="flex h-full  gap-5 "
          onClick={() => {
            scrollToTop();
          }}
        >
          <div className=" w-[150px] overflow-hidden h-full relative  flex justify-center  group">
            <img
              src={image}
              alt=""
              className="w-full h-[100px] max-w-full group-hover:opacity-80 group-hover:scale-105 transition-transform duration-200 object-cover shadow-lg rounded-sm"
            />
          </div>
          <div className="w-[60%] flex flex-col  gap-1 ">
            <h2
              className={`${textBlack ? "text-black" : "text-white"} text-xl md:text-2xl line-clamp-3 !font-medium`}
              style={{ lineHeight: "1.5" }}
            >
              {title}
            </h2>
            {showParagraph && (
              <p
                className={`${textBlack ? "text-black/80" : "text-white/80"} line-clamp-2 !font-normal`}
                style={{ lineHeight: "1.5" }}
              >
                {sub_title}
              </p>
            )}
            <div>
              {/* <div className="flex justify-start items-center gap-2 !text-sm my-[10px]">
              <div
              className={`${textBlack ? "text-black/80" : "text-white/80"} font-mukta `}
              >
              {" "}
              १२ श्रावण २०८१
              </div>
              <div
              className={`${textBlack ? "bg-black/80" : "bg-white/70"} h-[15px] w-[1px] `}
              ></div>
              <div
              className={`${textBlack ? "text-black/80" : "text-white/80"} font-mukta `}
              >
              कृषि पर्यावरण
              </div>
              </div> */}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SmallCardContentRight;
