import React, { useEffect, useState } from "react";
import { Button, Table, Modal, message, Skeleton } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Get, Delete } from "../../../redux/features/API";
import SubCategoryModify from "./Buttons/SubCategoryModify";
import { useNavigation } from "../../../Dashboard/NavigationContext";

const columns = (showModal, handleDelete) => [
  {
    title: "S.N",
    dataIndex: "key",
  },

  {
    title: "Sub-category Name",
    dataIndex: "category_key_name",
  },
  {
    title: "Category Name",
    dataIndex: "category_name",
  },
  {
    title: "Active",
    dataIndex: "active",
    render: (active) => (active ? "Yes" : "No"),
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (_, record) => (
      <div style={{ display: "flex", gap: "8px" }}>
        <Button
          type="primary"
          onClick={() => showModal(record)} // Pass the record to the showModal function
          className="bg-white text-black"
        >
          <EditOutlined />
        </Button>
        <Button type="danger" onClick={() => handleDelete(record)}>
          <DeleteOutlined />
        </Button>
      </div>
    ),
  },
];

const SubCategoryTable = ({ reload, setReload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const { lge } = useNavigation();
  const [selectedSubCategory, setSelectedSubCategory] = useState(null); // State to hold the selected subcategory
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  const token = localStorage.getItem("Token");
  const headers = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchData();
    setReload(false);
  }, [reload, lge]);

  const fetchData = async () => {
    setLoading(true); // Set loading to true before fetching data
    try {
      const response = await Get({
        url: "/category/sub-category",
        headers,
      });
      const filteredResponse = response.filter((item) => item.language === lge);
      const transformedData = filteredResponse.map((item) => ({
        key: item.id,
        category_name: item.category_name,
        category_key_name: item.category_key_name || "N/A",
        active: item.active,
        category: item.category,
      }));
      setDataSource(transformedData);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      message.error("Failed to load subcategories.");
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const handleDelete = (record) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Sub category?",
      content: `Category: ${record.category_key_name}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await Delete({
            url: `/category/sub-category/${record.key}`,
            headers,
          });
          message.success("Sub Category deleted successfully.");
          setDataSource((prev) =>
            prev.filter((item) => item.key !== record.key)
          );
        } catch (error) {
          console.error("Error deleting Sub category:", error);
          message.error("Failed to delete Sub category.");
        } finally {
          fetchData();
        }
      },
    });
  };

  const showModal = (subcategory) => {
    setSelectedSubCategory(subcategory); // Set the selected subcategory
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setSelectedSubCategory(null); // Reset selected subcategory
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedSubCategory(null); // Reset selected subcategory
  };

  return (
    <>
      <Table
        columns={columns(showModal, handleDelete)} // Pass confirmDelete to columns
        dataSource={dataSource}
        scroll={{ x: "max-content" }}
        loading={loading} // Show loading spinner while data is being fetched
      />
      <Modal
        title="Modify Sub Category"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer=""
      >
        <SubCategoryModify
          modifyObj={selectedSubCategory}
          fetchData={fetchData}
          handleCancel={handleCancel}
        />
      </Modal>
    </>
  );
};

export default SubCategoryTable;
