import React, { useEffect, useState } from "react";
import { Button, Table, Modal, message } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import InitialDelete from "./Buttons/InitialDelete";
import CategoryAdd from "./Buttons/CategoryAdd";
import { Get } from "../../../redux/features/API"; // Adjust the import path according to your structure

const columns = (showModal) => [
  {
    title: "S.N",
    dataIndex: "key",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Mobile Number",
    dataIndex: "phone_no", // Use the correct data field
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Address",
    dataIndex: "address",
  },
  {
    title: "Message",
    dataIndex: "message",
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (_, record) => (
      <div style={{ display: "flex", gap: "8px" }}>
        <Button type="danger">
          <InitialDelete />
        </Button>
      </div>
    ),
  },
];

const ContactTable = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("Token");
  const headers = { Authorization: `Bearer ${token}` };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await Get({ url: "/contact/contact", headers }); // Fetch data from the API
        const formattedData = response.map((item, index) => ({
          key: item.id, // Assuming the API returns an id
          name: item.name,
          phone_no: item.phone_no,
          email: item.email,
          address: item.address,
          message: item.message,
        }));
        setDataSource(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Failed to load contact data!");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Table
        columns={columns()}
        dataSource={dataSource}
        loading={loading} // Show loading indicator
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

export default ContactTable;
