import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./page/Home/Home";
import Navigation from "./common/navigation/Navigation";
import Footer from "./common/navigation/Footer";
import Login from "./page/Login/Login";
// import PageDashboard from "./page/Dashboard/PageDashboard";
import PageDashboard from "../Dashboard/PageDashboard";
import Story from "./page/Home/Story";
import Members from "./page/Home/Members";
import Table from "./page/Home/Table";
import IndividualAuthor from "./page/Home/IndividualAuthor";
import CategoryPage from "./common/cards/newscard/sideBarComponents/category page/CategoryPage";
import ScrollToTop from "./common/cards/newscard/sideBarComponents/ScrollToTop";
import SearchPage from "./common/navigation/Search Page/SearchPage";
import ErrorPage from "./page/ErrorPage/ErrorPage";
import { useTheme } from "../Dashboard/ThemeContext";
import AboutUs from "./page/ExtraPages/AboutUs";
import ContactUs from "./page/ExtraPages/ContactUs";
const PageUser = () => {
  const location = useLocation();
  const { bgColor } = useTheme();
  return (
    <div>
      {/* Render Navigation and Footer only outside dashboard routes */}
      {!location.pathname.toLowerCase().includes("/dashboard") && (
        <div
          className="sticky top-[-101px] z-50"
          // style={{ backgroundColor: bgColor }}
        >
          <Navigation />
        </div>
      )}
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/en" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/dashboard/*" element={<PageDashboard />} />
        <Route path="/dashboard/*" element={<PageDashboard />} />
        <Route path="/dashboard/login" element={<Login />} />
        <Route path="/story/:year/:month/:day/:newsId" element={<Story />} />
        <Route
          path="/en/story/:year/:month/:day/:newsId/:newsTitle"
          element={<Story />}
        />
        <Route path="/member" element={<Members />} />
        <Route path="/en/member" element={<Members />} />
        <Route path="/author/:authorId" element={<IndividualAuthor />} />
        <Route path="/en/author/:authorId" element={<IndividualAuthor />} />
        <Route path="/table" element={<Table />} />
        <Route path="/:categoryName" element={<CategoryPage />} />
        <Route path="/en/:categoryName" element={<CategoryPage />} />
        <Route path="/search/:searchValue" element={<SearchPage />} />
        <Route path="/en/search/:searchValue" element={<SearchPage />} />
        {/* <Route path="*" element={<ErrorPage />} /> */}
      </Routes>
      {!location.pathname.toLowerCase().includes("/dashboard") && <Footer />}
    </div>
  );
};

export default PageUser;
