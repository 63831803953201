import React, { createContext, useState, useEffect, useContext } from "react";
import { Get } from "../redux/features/API"; // Adjust the import as necessary

// Create a Context
const NewsContext = createContext();

// Create a Provider component
export const NewsProvider = ({ children }) => {
  const [wholeNews, setWholeNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lge, setLge] = useState(
    window.location.pathname.includes("/en") ? "en" : "np"
  );

  // Function to fetch news
  const fetchNews = async () => {
    setLoading(true);
    try {
      const response = await Get({ url: "/public/news/get-news" });
      const filteredResponse = response
        .filter((item) => item.language === lge && item.active === true)
        .sort((a, b) => b.id - a.id); // Sorting in descending order by id

      setWholeNews(filteredResponse);
    } catch (error) {
      console.error("Error in news fetch: " + error);
      setWholeNews([]); // Reset news in case of error
    } finally {
      setLoading(false);
    }
  };

  // Fetch news on language change or component mount
  useEffect(() => {
    fetchNews(); // Fetch news every time the language changes or component mounts
  }, [lge]); // This effect runs when 'lge' changes or on component mount

  return (
    <NewsContext.Provider value={{ wholeNews, loading, setWholeNews }}>
      {children}
    </NewsContext.Provider>
  );
};

// Custom hook for using the context
export const useNews = () => useContext(NewsContext);
