import React from "react";
import "./Login.css";
// import img1 from "../../../images/logofooter.png";
import LogoImg from "./logo.png";
import { useState } from "react";
import { TextField } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useContext } from "react";
import LoginContext from "../../../context/LoginProvider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import { Input, Button } from "antd";
import { message } from "antd";
import { Post } from "../../../redux/features/API";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [inputfield, setinputfield] = useState({ username: "", password: "" });
  const inputfieldChange = (e) => {
    setinputfield({ ...inputfield, [e.target.name]: e.target.value });
  };
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("Token")) {
      navigate("/dashboard");
    } // eslint-disable-next-line
  }, []);
  const handleLogin = async (values) => {
    setLoading(true); // Start loading state

    if (values.username === "" || values.password === "") {
      message.error("Please Enter all details");
      setLoading(false); // Stop loading state on validation error
      return;
    }

    try {
      const response = await Post({ url: "/user/login/", data: values });
      localStorage.setItem("Token", response.tokens.access);
      navigate("/dashboard");
      console.log(response);
    } catch (error) {
      console.error("Login failed:", error);
      message.error("Please Enter Correct Username or Password");
    } finally {
      setLoading(false); // Stop loading state in both success and error cases
    }
  };

  return (
    <>
      <div id="container" className="overflow-hidden">
        {" "}
      </div>
      <div id="loginform" className="overflow-hidden">
        <Form onFinish={handleLogin}>
          {/* <img src={img1} alt="#" loading="lazy" className="!w-[300px] !m-0" /> */}
          <img src={LogoImg} />
          <div id="inputfields">
            <div className="username h-full w-full relative">
              <Form.Item name="user_name">
                <Input placeholder="Username" />
              </Form.Item>
            </div>

            <div className="username h-full w-full relative">
              <Form.Item name="password">
                <Input.Password placeholder="Password" />
              </Form.Item>
            </div>

            <div>
              <Button
                loading={loading}
                id="log"
                className=" h-full w-full flex justify-center items-center bg-transparent "
                type="primary"
                htmlType="submit"
              >
                Sign In
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Login;
