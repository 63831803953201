// App.js

import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import makeStore from "./redux/store";
import PageUser from "./User/PageUser";
// import UserState from "./context/userState";

import { Toaster } from "sonner";
import { NavigationProvider } from "./Dashboard/NavigationContext";
import { LoginProvider } from "./context/LoginProvider";
import { NewsProvider } from "./Dashboard/NewsContext";
import { AuthorProvider } from "./Dashboard/AuthorContext";
import { AdsProvider } from "./Dashboard/AdsContext";
import { ThemeProvider } from "./Dashboard/ThemeContext";
import { CountProvider } from "./Dashboard/CountContext";

function App() {
  return (
    <React.Fragment>
      <Provider store={makeStore()}>
        <BrowserRouter>
          <NavigationProvider>
            <NewsProvider>
              <AdsProvider>
                <AuthorProvider>
                  <CountProvider>
                    <ThemeProvider>
                      <PageUser/>
                      <Toaster richColors position="top-right" closeButton />
                    </ThemeProvider>
                  </CountProvider>
                </AuthorProvider>
              </AdsProvider>
            </NewsProvider>
          </NavigationProvider>
        </BrowserRouter>
      </Provider>
    </React.Fragment>
  );
}

export default App;
