import React, { useState, useEffect } from "react";
import { Button, Table, Modal, message } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import CategoryAdd from "./Buttons/CategoryAdd";
import { Get, Post, Delete } from "../../../redux/features/API";
import CategoryModify from "./Buttons/CategoryModify";
import { useNavigation } from "../../../Dashboard/NavigationContext";
const columns = (showModal, handleDelete) => [
  {
    title: "Display order",
    dataIndex: "order",
  },
  {
    title: "Category",
    dataIndex: "category_name",
  },
  {
    title: "Subcategory",
    dataIndex: "subcategory_key_name",
  },
  {
    title: "Display Order",
    dataIndex: "display_order",
  },
  {
    title: "Active",
    dataIndex: "active",
    render: (active) => (active ? "Yes" : "No"),
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (_, record) => (
      <div style={{ display: "flex", gap: "8px" }}>
        <Button
          type="primary"
          onClick={() => showModal(record)} // Pass record to showModal
          className="bg-white text-black"
        >
          <EditOutlined />
        </Button>
        <Button type="danger" onClick={() => handleDelete(record)}>
          <DeleteOutlined />
        </Button>
      </div>
    ),
  },
];

const CategoryTable = ({ reload, setReload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { lge } = useNavigation();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null); // State to hold the selected category

  const showModal = (category) => {
    setSelectedCategory(category); // Set the selected category
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setSelectedCategory(null); // Reset the selected category
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedCategory(null); // Reset the selected category
  };

  const token = localStorage.getItem("Token");
  const headers = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchData();
    setReload(false);
  }, [reload, lge]);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Get({ url: "/category/category", headers });
      const filteredResponse = response
        .filter((item) => item.language === lge)
        .sort((a, b) => b.id - a.id);
      const transformedData = filteredResponse.map((item) => ({
        key: item.id,
        order: item.display_order,
        category_name: item.category_name,
        subcategory_key_name: item.category_key.length || "0",
        display_order: item.display_order,
        active: item.active,
        language: item.language,
      }));
      setDataSource(transformedData);
    } catch (error) {
      console.error("Error fetching categories:", error);
      message.error("Failed to load categories.");
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  const handleDelete = (record) => {
    Modal.confirm({
      title: "Are you sure you want to delete this category?",
      content: `Category: ${record.category_name}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await Delete({ url: `/category/category/${record.key}`, headers });
          message.success("Category deleted successfully.");
          setDataSource((prev) =>
            prev.filter((item) => item.key !== record.key)
          );
        } catch (error) {
          console.error("Error deleting category:", error);
          message.error("Failed to delete category.");
        } finally {
          fetchData();
        }
      },
    });
  };

  return (
    <>
      <Table
        columns={columns(showModal, handleDelete)}
        dataSource={dataSource}
        scroll={{ x: "max-content" }}
        loading={loading}
      />
      <Modal
        title="Modify Category"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer=""
        okButtonProps={{
          style: { color: "black" },
        }}
      >
        <CategoryModify
          modifyObj={selectedCategory}
          fetchData={fetchData}
          handleCancel={handleCancel}
        />{" "}
        {/* Pass selected category to CategoryAdd */}
      </Modal>
    </>
  );
};

export default CategoryTable;
