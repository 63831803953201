import React, { useState, useEffect } from "react";
import { Button, Table, Modal, message, Spin } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import NewsModify from "./Buttons/NewsModify";
import { Get, Delete } from "../../../redux/features/API";
import { useNavigation } from "../../../Dashboard/NavigationContext";

const NewsTable = ({ reload, setReload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const { lge } = useNavigation();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("Token");
  const headers = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    fetchData();
    setReload(false);
  }, [lge, reload]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await Get({ url: "/news/news", headers });
      const requiredData = response
        .filter((item) => item.language === `${lge}`)
        .sort((a, b) => b.id - a.id);
      const transformedData = requiredData.map((item) => ({
        key: item.id,
        language: item.language,
        news_title: item.news_title,
        news_sub_title: item.news_sub_title,
        author_name: item.author_name,
        news_post: item.news_post,
        self_date: item.self_date,
        image: item.image,
        active: item.active,
        breaking_news: item.breaking_news,
        category: item.category, // Change to category ID
        category_name: item.category_name, // Store name for display
        category_key: item.category_key,
      }));
      setDataSource(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Failed to fetch news data!");
    } finally {
      setLoading(false);
    }
  };

  const showModal = (news) => {
    setSelectedNews(news);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedNews(null);
  };

  const handleDelete = async () => {
    if (!selectedNews) return;
    const newsId = selectedNews.key;
    try {
      await Delete({ url: `/news/news/${newsId}`, headers });
      message.success("News item deleted successfully!");
      fetchData();
    } catch (error) {
      console.error("Error deleting news:", error);
      message.error("Failed to delete news item!");
    } finally {
      setIsDeleteModalOpen(false);
      setSelectedNews(null);
    }
  };

  const columns = [
    {
      title: "S.N",
      dataIndex: "key",
      render: (_, __, index) => index + 1,
      width: 50,
    },
    {
      title: "News Title",
      dataIndex: "news_title",
      width: 200,
    },
    {
      title: "News Sub Title",
      dataIndex: "news_sub_title",
      width: 150,
    },
    // {
    //   title: "News Post",
    //   dataIndex: "news_post",
    //   render: (text) => {
    //     const firstTenWords = text.split(" ").slice(0, 7).join(" ");
    //     return (
    //       <div
    //         style={{
    //           maxHeight: "3em",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //           display: "-webkit-box",
    //           WebkitBoxOrient: "vertical",
    //           WebkitLineClamp: 3,
    //         }}
    //       >
    //         {firstTenWords}
    //       </div>
    //     );
    //   },
    //   width: 200,
    // },
    {
      title: "Date",
      dataIndex: "self_date",
      width: 120,
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (image) => (
        <img src={image} alt="News" style={{ width: 50, height: 50 }} />
      ),
      width: 80,
    },
    {
      title: "Category",
      dataIndex: "category_name", // Display category name
      width: 100,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Button
            type="primary"
            onClick={() => showModal(record)}
            // style={{ color: "green", border: "1px solid #bdbbbb" }}
            className="bg-white text-black"
          >
            <EditOutlined />
          </Button>
          <Button
            type="danger"
            onClick={() => {
              setSelectedNews(record);
              setIsDeleteModalOpen(true);
            }}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
      width: 100,
    },
  ];

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <Table
          columns={columns}
          dataSource={dataSource}
          rowKey="key"
          loading={loading}
          scroll={{ x: "max-content" }}
        />
      </div>
      <Modal
        title="Modify News"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="min-w-[60vw]"
      >
        <NewsModify
          modifyObj={selectedNews}
          onClose={handleCancel}
          fetchData={fetchData}
          handleCancel={handleCancel}
        />
      </Modal>
      <Modal
        title="Confirm Deletion"
        open={isDeleteModalOpen}
        onOk={handleDelete}
        onCancel={() => setIsDeleteModalOpen(false)}
      >
        <p>Are you sure you want to delete this news item?</p>
      </Modal>
    </>
  );
};

export default NewsTable;
